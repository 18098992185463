/* eslint-disable */
// @generated by protobuf-ts 2.9.4 with parameter long_type_string,client_generic,server_none,eslint_disable,optimize_code_size
// @generated from protobuf file "video/sfu/models/models.proto" (package "stream.video.sfu.models", syntax proto3)
// tslint:disable
import { MessageType } from '@protobuf-ts/runtime';
import { Struct } from '../../../google/protobuf/struct';
import { Timestamp } from '../../../google/protobuf/timestamp';
/**
 * CallState is the current state of the call
 * as seen by an SFU.
 *
 * @generated from protobuf message stream.video.sfu.models.CallState
 */
export interface CallState {
  /**
   * participants is the list of participants in the call.
   * In large calls, the list could be truncated in which
   * case, the list of participants contains fewer participants
   * than the counts returned in participant_count. Anonymous
   * participants are **NOT** included in the list.
   *
   * @generated from protobuf field: repeated stream.video.sfu.models.Participant participants = 1;
   */
  participants: Participant[];
  /**
   * started_at is the time the call session actually started.
   *
   * @generated from protobuf field: google.protobuf.Timestamp started_at = 2;
   */
  startedAt?: Timestamp;
  /**
   * participant_count contains the summary of the counts.
   *
   * @generated from protobuf field: stream.video.sfu.models.ParticipantCount participant_count = 3;
   */
  participantCount?: ParticipantCount;
  /**
   * the list of pins in the call.
   * Pins are ordered in descending order (most important first).
   *
   * @generated from protobuf field: repeated stream.video.sfu.models.Pin pins = 4;
   */
  pins: Pin[];
}
/**
 * @generated from protobuf message stream.video.sfu.models.ParticipantCount
 */
export interface ParticipantCount {
  /**
   * Total number of participants in the call including
   * the anonymous participants.
   *
   * @generated from protobuf field: uint32 total = 1;
   */
  total: number;
  /**
   * Total number of anonymous participants in the call.
   *
   * @generated from protobuf field: uint32 anonymous = 2;
   */
  anonymous: number;
}
/**
 * @generated from protobuf message stream.video.sfu.models.Pin
 */
export interface Pin {
  /**
   * the user to pin
   *
   * @generated from protobuf field: string user_id = 1;
   */
  userId: string;
  /**
   * the user sesion_id to pin, if not provided, applies to all sessions
   *
   * @generated from protobuf field: string session_id = 2;
   */
  sessionId: string;
}
/**
 * those who are online in the call
 *
 * @generated from protobuf message stream.video.sfu.models.Participant
 */
export interface Participant {
  /**
   * @generated from protobuf field: string user_id = 1;
   */
  userId: string;
  /**
   * @generated from protobuf field: string session_id = 2;
   */
  sessionId: string;
  /**
   * map of track id to track type
   *
   * @generated from protobuf field: repeated stream.video.sfu.models.TrackType published_tracks = 3;
   */
  publishedTracks: TrackType[];
  /**
   * @generated from protobuf field: google.protobuf.Timestamp joined_at = 4;
   */
  joinedAt?: Timestamp;
  /**
   * @generated from protobuf field: string track_lookup_prefix = 5;
   */
  trackLookupPrefix: string;
  /**
   * @generated from protobuf field: stream.video.sfu.models.ConnectionQuality connection_quality = 6;
   */
  connectionQuality: ConnectionQuality;
  /**
   * @generated from protobuf field: bool is_speaking = 7;
   */
  isSpeaking: boolean;
  /**
   * @generated from protobuf field: bool is_dominant_speaker = 8;
   */
  isDominantSpeaker: boolean;
  /**
   * @generated from protobuf field: float audio_level = 9;
   */
  audioLevel: number;
  /**
   * @generated from protobuf field: string name = 10;
   */
  name: string;
  /**
   * @generated from protobuf field: string image = 11;
   */
  image: string;
  /**
   * @generated from protobuf field: google.protobuf.Struct custom = 12;
   */
  custom?: Struct;
  /**
   * @generated from protobuf field: repeated string roles = 13;
   */
  roles: string[];
}
/**
 * @generated from protobuf message stream.video.sfu.models.StreamQuality
 */
export interface StreamQuality {
  /**
   * @generated from protobuf field: stream.video.sfu.models.VideoQuality video_quality = 1;
   */
  videoQuality: VideoQuality;
  /**
   * @generated from protobuf field: string user_id = 2;
   */
  userId: string;
}
/**
 * @generated from protobuf message stream.video.sfu.models.VideoDimension
 */
export interface VideoDimension {
  /**
   * @generated from protobuf field: uint32 width = 1;
   */
  width: number;
  /**
   * @generated from protobuf field: uint32 height = 2;
   */
  height: number;
}
/**
 * @generated from protobuf message stream.video.sfu.models.VideoLayer
 */
export interface VideoLayer {
  /**
   * for tracks with a single layer, this should be HIGH
   *
   * @generated from protobuf field: string rid = 1;
   */
  rid: string;
  /**
   * @generated from protobuf field: stream.video.sfu.models.VideoDimension video_dimension = 2;
   */
  videoDimension?: VideoDimension;
  /**
   * target bitrate, server will measure actual
   *
   * @generated from protobuf field: uint32 bitrate = 4;
   */
  bitrate: number;
  /**
   * @generated from protobuf field: uint32 fps = 5;
   */
  fps: number;
  /**
   * @generated from protobuf field: stream.video.sfu.models.VideoQuality quality = 6;
   */
  quality: VideoQuality;
}
/**
 * @generated from protobuf message stream.video.sfu.models.PublishOption
 */
export interface PublishOption {
  /**
   * @generated from protobuf field: stream.video.sfu.models.TrackType track_type = 1;
   */
  trackType: TrackType;
  /**
   * @generated from protobuf field: stream.video.sfu.models.Codec codec = 2;
   */
  codec?: Codec;
  /**
   * @generated from protobuf field: int32 bitrate = 3;
   */
  bitrate: number;
  /**
   * @generated from protobuf field: int32 fps = 4;
   */
  fps: number;
  /**
   * @generated from protobuf field: int32 max_spatial_layers = 5;
   */
  maxSpatialLayers: number;
  /**
   * @generated from protobuf field: int32 max_temporal_layers = 6;
   */
  maxTemporalLayers: number;
}
/**
 * @generated from protobuf message stream.video.sfu.models.Codec
 */
export interface Codec {
  /**
   * @generated from protobuf field: uint32 payload_type = 16;
   */
  payloadType: number;
  /**
   * @generated from protobuf field: string name = 10;
   */
  name: string;
  /**
   * @generated from protobuf field: uint32 clock_rate = 14;
   */
  clockRate: number;
  /**
   * @generated from protobuf field: string encoding_parameters = 15;
   */
  encodingParameters: string;
  /**
   * @generated from protobuf field: string fmtp = 12;
   */
  fmtp: string;
}
/**
 * @generated from protobuf message stream.video.sfu.models.ICETrickle
 */
export interface ICETrickle {
  /**
   * @generated from protobuf field: stream.video.sfu.models.PeerType peer_type = 1;
   */
  peerType: PeerType;
  /**
   * @generated from protobuf field: string ice_candidate = 2;
   */
  iceCandidate: string;
  /**
   * @generated from protobuf field: string session_id = 3;
   */
  sessionId: string;
}
/**
 * @generated from protobuf message stream.video.sfu.models.TrackInfo
 */
export interface TrackInfo {
  /**
   * @generated from protobuf field: string track_id = 1;
   */
  trackId: string;
  /**
   * @generated from protobuf field: stream.video.sfu.models.TrackType track_type = 2;
   */
  trackType: TrackType;
  /**
   * @generated from protobuf field: repeated stream.video.sfu.models.VideoLayer layers = 5;
   */
  layers: VideoLayer[];
  /**
   * @generated from protobuf field: string mid = 6;
   */
  mid: string;
  /**
   * for audio tracks
   *
   * @generated from protobuf field: bool dtx = 7;
   */
  dtx: boolean;
  /**
   * @generated from protobuf field: bool stereo = 8;
   */
  stereo: boolean;
  /**
   * @generated from protobuf field: bool red = 9;
   */
  red: boolean;
  /**
   * @generated from protobuf field: bool muted = 10;
   */
  muted: boolean;
}
/**
 * @generated from protobuf message stream.video.sfu.models.Error
 */
export interface Error {
  /**
   * @generated from protobuf field: stream.video.sfu.models.ErrorCode code = 1;
   */
  code: ErrorCode;
  /**
   * @generated from protobuf field: string message = 2;
   */
  message: string;
  /**
   * @generated from protobuf field: bool should_retry = 3;
   */
  shouldRetry: boolean;
}
/**
 * @generated from protobuf message stream.video.sfu.models.ClientDetails
 */
export interface ClientDetails {
  /**
   * @generated from protobuf field: stream.video.sfu.models.Sdk sdk = 1;
   */
  sdk?: Sdk;
  /**
   * @generated from protobuf field: stream.video.sfu.models.OS os = 2;
   */
  os?: OS;
  /**
   * @generated from protobuf field: stream.video.sfu.models.Browser browser = 3;
   */
  browser?: Browser;
  /**
   * @generated from protobuf field: stream.video.sfu.models.Device device = 4;
   */
  device?: Device;
}
/**
 * @generated from protobuf message stream.video.sfu.models.Sdk
 */
export interface Sdk {
  /**
   * @generated from protobuf field: stream.video.sfu.models.SdkType type = 1;
   */
  type: SdkType;
  /**
   * @generated from protobuf field: string major = 2;
   */
  major: string;
  /**
   * @generated from protobuf field: string minor = 3;
   */
  minor: string;
  /**
   * @generated from protobuf field: string patch = 4;
   */
  patch: string;
}
/**
 * @generated from protobuf message stream.video.sfu.models.OS
 */
export interface OS {
  /**
   * @generated from protobuf field: string name = 1;
   */
  name: string;
  /**
   * @generated from protobuf field: string version = 2;
   */
  version: string;
  /**
   * @generated from protobuf field: string architecture = 3;
   */
  architecture: string;
}
/**
 * @generated from protobuf message stream.video.sfu.models.Browser
 */
export interface Browser {
  /**
   * @generated from protobuf field: string name = 1;
   */
  name: string;
  /**
   * @generated from protobuf field: string version = 2;
   */
  version: string;
}
/**
 * @generated from protobuf message stream.video.sfu.models.Device
 */
export interface Device {
  /**
   * @generated from protobuf field: string name = 1;
   */
  name: string;
  /**
   * @generated from protobuf field: string version = 2;
   */
  version: string;
}
/**
 * @generated from protobuf message stream.video.sfu.models.Call
 */
export interface Call {
  /**
   * the call type
   *
   * @generated from protobuf field: string type = 1;
   */
  type: string;
  /**
   * the call id
   *
   * @generated from protobuf field: string id = 2;
   */
  id: string;
  /**
   * the id of the user that created this call
   *
   * @generated from protobuf field: string created_by_user_id = 3;
   */
  createdByUserId: string;
  /**
   * the id of the current host for this call
   *
   * @generated from protobuf field: string host_user_id = 4;
   */
  hostUserId: string;
  /**
   * @generated from protobuf field: google.protobuf.Struct custom = 5;
   */
  custom?: Struct;
  /**
   * @generated from protobuf field: google.protobuf.Timestamp created_at = 6;
   */
  createdAt?: Timestamp;
  /**
   * @generated from protobuf field: google.protobuf.Timestamp updated_at = 7;
   */
  updatedAt?: Timestamp;
}
/**
 * CallGrants represents the set of permissions given
 * to the user for the current call.
 *
 * @generated from protobuf message stream.video.sfu.models.CallGrants
 */
export interface CallGrants {
  /**
   * @generated from protobuf field: bool can_publish_audio = 1;
   */
  canPublishAudio: boolean;
  /**
   * @generated from protobuf field: bool can_publish_video = 2;
   */
  canPublishVideo: boolean;
  /**
   * @generated from protobuf field: bool can_screenshare = 3;
   */
  canScreenshare: boolean;
}
/**
 * @generated from protobuf message stream.video.sfu.models.InputDevices
 */
export interface InputDevices {
  /**
   * @generated from protobuf field: repeated string available_devices = 1;
   */
  availableDevices: string[];
  /**
   * @generated from protobuf field: string current_device = 2;
   */
  currentDevice: string;
  /**
   * @generated from protobuf field: bool is_permitted = 3;
   */
  isPermitted: boolean;
}
/**
 * @generated from protobuf message stream.video.sfu.models.AndroidState
 */
export interface AndroidState {
  /**
   * @generated from protobuf field: stream.video.sfu.models.AndroidThermalState thermal_state = 1;
   */
  thermalState: AndroidThermalState;
  /**
   * @generated from protobuf field: bool is_power_saver_mode = 2;
   */
  isPowerSaverMode: boolean;
}
/**
 * @generated from protobuf message stream.video.sfu.models.AppleState
 */
export interface AppleState {
  /**
   * @generated from protobuf field: stream.video.sfu.models.AppleThermalState thermal_state = 1;
   */
  thermalState: AppleThermalState;
  /**
   * https://developer.apple.com/documentation/foundation/processinfo/1617047-islowpowermodeenabled
   *
   * @generated from protobuf field: bool is_low_power_mode_enabled = 2;
   */
  isLowPowerModeEnabled: boolean;
}
/**
 * @generated from protobuf enum stream.video.sfu.models.PeerType
 */
export enum PeerType {
  /**
   * todo fix me (marcelo)
   *
   * @generated from protobuf enum value: PEER_TYPE_PUBLISHER_UNSPECIFIED = 0;
   */
  PUBLISHER_UNSPECIFIED = 0,
  /**
   * @generated from protobuf enum value: PEER_TYPE_SUBSCRIBER = 1;
   */
  SUBSCRIBER = 1,
}
/**
 * @generated from protobuf enum stream.video.sfu.models.ConnectionQuality
 */
export enum ConnectionQuality {
  /**
   * @generated from protobuf enum value: CONNECTION_QUALITY_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,
  /**
   * @generated from protobuf enum value: CONNECTION_QUALITY_POOR = 1;
   */
  POOR = 1,
  /**
   * @generated from protobuf enum value: CONNECTION_QUALITY_GOOD = 2;
   */
  GOOD = 2,
  /**
   * @generated from protobuf enum value: CONNECTION_QUALITY_EXCELLENT = 3;
   */
  EXCELLENT = 3,
}
/**
 * @generated from protobuf enum stream.video.sfu.models.VideoQuality
 */
export enum VideoQuality {
  /**
   * @generated from protobuf enum value: VIDEO_QUALITY_LOW_UNSPECIFIED = 0;
   */
  LOW_UNSPECIFIED = 0,
  /**
   * @generated from protobuf enum value: VIDEO_QUALITY_MID = 1;
   */
  MID = 1,
  /**
   * @generated from protobuf enum value: VIDEO_QUALITY_HIGH = 2;
   */
  HIGH = 2,
  /**
   * @generated from protobuf enum value: VIDEO_QUALITY_OFF = 3;
   */
  OFF = 3,
}
/**
 * @generated from protobuf enum stream.video.sfu.models.TrackType
 */
export enum TrackType {
  /**
   * @generated from protobuf enum value: TRACK_TYPE_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,
  /**
   * @generated from protobuf enum value: TRACK_TYPE_AUDIO = 1;
   */
  AUDIO = 1,
  /**
   * @generated from protobuf enum value: TRACK_TYPE_VIDEO = 2;
   */
  VIDEO = 2,
  /**
   * @generated from protobuf enum value: TRACK_TYPE_SCREEN_SHARE = 3;
   */
  SCREEN_SHARE = 3,
  /**
   * @generated from protobuf enum value: TRACK_TYPE_SCREEN_SHARE_AUDIO = 4;
   */
  SCREEN_SHARE_AUDIO = 4,
}
/**
 * @generated from protobuf enum stream.video.sfu.models.ErrorCode
 */
export enum ErrorCode {
  /**
   * @generated from protobuf enum value: ERROR_CODE_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,
  /**
   * @generated from protobuf enum value: ERROR_CODE_PUBLISH_TRACK_NOT_FOUND = 100;
   */
  PUBLISH_TRACK_NOT_FOUND = 100,
  /**
   * @generated from protobuf enum value: ERROR_CODE_PUBLISH_TRACKS_MISMATCH = 101;
   */
  PUBLISH_TRACKS_MISMATCH = 101,
  /**
   * @generated from protobuf enum value: ERROR_CODE_PUBLISH_TRACK_OUT_OF_ORDER = 102;
   */
  PUBLISH_TRACK_OUT_OF_ORDER = 102,
  /**
   * @generated from protobuf enum value: ERROR_CODE_PUBLISH_TRACK_VIDEO_LAYER_NOT_FOUND = 103;
   */
  PUBLISH_TRACK_VIDEO_LAYER_NOT_FOUND = 103,
  /**
   * @generated from protobuf enum value: ERROR_CODE_LIVE_ENDED = 104;
   */
  LIVE_ENDED = 104,
  /**
   * @generated from protobuf enum value: ERROR_CODE_PARTICIPANT_NOT_FOUND = 200;
   */
  PARTICIPANT_NOT_FOUND = 200,
  /**
   * @generated from protobuf enum value: ERROR_CODE_PARTICIPANT_MIGRATING_OUT = 201;
   */
  PARTICIPANT_MIGRATING_OUT = 201,
  /**
   * @generated from protobuf enum value: ERROR_CODE_PARTICIPANT_MIGRATION_FAILED = 202;
   */
  PARTICIPANT_MIGRATION_FAILED = 202,
  /**
   * @generated from protobuf enum value: ERROR_CODE_PARTICIPANT_MIGRATING = 203;
   */
  PARTICIPANT_MIGRATING = 203,
  /**
   * @generated from protobuf enum value: ERROR_CODE_PARTICIPANT_RECONNECT_FAILED = 204;
   */
  PARTICIPANT_RECONNECT_FAILED = 204,
  /**
   * @generated from protobuf enum value: ERROR_CODE_PARTICIPANT_MEDIA_TRANSPORT_FAILURE = 205;
   */
  PARTICIPANT_MEDIA_TRANSPORT_FAILURE = 205,
  /**
   * @generated from protobuf enum value: ERROR_CODE_CALL_NOT_FOUND = 300;
   */
  CALL_NOT_FOUND = 300,
  /**
   * @generated from protobuf enum value: ERROR_CODE_REQUEST_VALIDATION_FAILED = 400;
   */
  REQUEST_VALIDATION_FAILED = 400,
  /**
   * @generated from protobuf enum value: ERROR_CODE_UNAUTHENTICATED = 401;
   */
  UNAUTHENTICATED = 401,
  /**
   * @generated from protobuf enum value: ERROR_CODE_PERMISSION_DENIED = 403;
   */
  PERMISSION_DENIED = 403,
  /**
   * @generated from protobuf enum value: ERROR_CODE_TOO_MANY_REQUESTS = 429;
   */
  TOO_MANY_REQUESTS = 429,
  /**
   * @generated from protobuf enum value: ERROR_CODE_INTERNAL_SERVER_ERROR = 500;
   */
  INTERNAL_SERVER_ERROR = 500,
  /**
   * @generated from protobuf enum value: ERROR_CODE_SFU_SHUTTING_DOWN = 600;
   */
  SFU_SHUTTING_DOWN = 600,
  /**
   * @generated from protobuf enum value: ERROR_CODE_SFU_FULL = 700;
   */
  SFU_FULL = 700,
}
/**
 * @generated from protobuf enum stream.video.sfu.models.SdkType
 */
export enum SdkType {
  /**
   * @generated from protobuf enum value: SDK_TYPE_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,
  /**
   * @generated from protobuf enum value: SDK_TYPE_REACT = 1;
   */
  REACT = 1,
  /**
   * @generated from protobuf enum value: SDK_TYPE_ANGULAR = 2;
   */
  ANGULAR = 2,
  /**
   * @generated from protobuf enum value: SDK_TYPE_ANDROID = 3;
   */
  ANDROID = 3,
  /**
   * @generated from protobuf enum value: SDK_TYPE_IOS = 4;
   */
  IOS = 4,
  /**
   * @generated from protobuf enum value: SDK_TYPE_FLUTTER = 5;
   */
  FLUTTER = 5,
  /**
   * @generated from protobuf enum value: SDK_TYPE_REACT_NATIVE = 6;
   */
  REACT_NATIVE = 6,
  /**
   * @generated from protobuf enum value: SDK_TYPE_UNITY = 7;
   */
  UNITY = 7,
  /**
   * @generated from protobuf enum value: SDK_TYPE_GO = 8;
   */
  GO = 8,
  /**
   * @generated from protobuf enum value: SDK_TYPE_PLAIN_JAVASCRIPT = 9;
   */
  PLAIN_JAVASCRIPT = 9,
}
/**
 * @generated from protobuf enum stream.video.sfu.models.TrackUnpublishReason
 */
export enum TrackUnpublishReason {
  /**
   * Default value which is used when the specific reason
   * for muting the track is not known.
   *
   * @generated from protobuf enum value: TRACK_UNPUBLISH_REASON_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,
  /**
   * Represents user muting their tracks.
   *
   * @generated from protobuf enum value: TRACK_UNPUBLISH_REASON_USER_MUTED = 1;
   */
  USER_MUTED = 1,
  /**
   * Represents muting the track because the permission to
   * publish the track has been revoked.
   *
   * @generated from protobuf enum value: TRACK_UNPUBLISH_REASON_PERMISSION_REVOKED = 2;
   */
  PERMISSION_REVOKED = 2,
  /**
   * Represents muting the track due to moderation actions.
   * This is different from permission revoked because the
   * participant can unmute themselves here whereas in case
   * of "permission revoke" it is not possible until the
   * call permissions are updated.
   *
   * @generated from protobuf enum value: TRACK_UNPUBLISH_REASON_MODERATION = 3;
   */
  MODERATION = 3,
}
/**
 * GoAwayReason represents the reason for the SFU to
 * disconnect the client.
 *
 * @generated from protobuf enum stream.video.sfu.models.GoAwayReason
 */
export enum GoAwayReason {
  /**
   * @generated from protobuf enum value: GO_AWAY_REASON_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,
  /**
   * @generated from protobuf enum value: GO_AWAY_REASON_SHUTTING_DOWN = 1;
   */
  SHUTTING_DOWN = 1,
  /**
   * @generated from protobuf enum value: GO_AWAY_REASON_REBALANCE = 2;
   */
  REBALANCE = 2,
}
/**
 * CallEndedReason represents the reason for the call to end.
 *
 * @generated from protobuf enum stream.video.sfu.models.CallEndedReason
 */
export enum CallEndedReason {
  /**
   * @generated from protobuf enum value: CALL_ENDED_REASON_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,
  /**
   * @generated from protobuf enum value: CALL_ENDED_REASON_ENDED = 1;
   */
  ENDED = 1,
  /**
   * @generated from protobuf enum value: CALL_ENDED_REASON_LIVE_ENDED = 2;
   */
  LIVE_ENDED = 2,
  /**
   * @generated from protobuf enum value: CALL_ENDED_REASON_KICKED = 3;
   */
  KICKED = 3,
  /**
   * @generated from protobuf enum value: CALL_ENDED_REASON_SESSION_ENDED = 4;
   */
  SESSION_ENDED = 4,
}
/**
 * WebsocketReconnectStrategy defines the ws strategies available for handling reconnections.
 *
 * @generated from protobuf enum stream.video.sfu.models.WebsocketReconnectStrategy
 */
export enum WebsocketReconnectStrategy {
  /**
   * @generated from protobuf enum value: WEBSOCKET_RECONNECT_STRATEGY_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,
  /**
   * Sent after reaching the maximum reconnection attempts, or any other unrecoverable error leading to permanent disconnect.
   *
   * @generated from protobuf enum value: WEBSOCKET_RECONNECT_STRATEGY_DISCONNECT = 1;
   */
  DISCONNECT = 1,
  /**
   * SDK should maintaining existing publisher/subscriber pc instances
   * and establish a new WebSocket connection.
   *
   * @generated from protobuf enum value: WEBSOCKET_RECONNECT_STRATEGY_FAST = 2;
   */
  FAST = 2,
  /**
   * SDK should obtain new credentials from the coordinator, drops existing pc instances, set a new session_id and initializes
   * a completely new WebSocket connection, ensuring a comprehensive reset.
   *
   * @generated from protobuf enum value: WEBSOCKET_RECONNECT_STRATEGY_REJOIN = 3;
   */
  REJOIN = 3,
  /**
   * SDK should migrate to a new SFU instance
   *
   * @generated from protobuf enum value: WEBSOCKET_RECONNECT_STRATEGY_MIGRATE = 4;
   */
  MIGRATE = 4,
}
/**
 * AndroidThermalState is reported by the Android API. The full list of values is documented here
 * https://developer.android.com/reference/android/os/PowerManager.html#getCurrentThermalStatus()
 *
 * @generated from protobuf enum stream.video.sfu.models.AndroidThermalState
 */
export enum AndroidThermalState {
  /**
   * @generated from protobuf enum value: ANDROID_THERMAL_STATE_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,
  /**
   * @generated from protobuf enum value: ANDROID_THERMAL_STATE_NONE = 1;
   */
  NONE = 1,
  /**
   * @generated from protobuf enum value: ANDROID_THERMAL_STATE_LIGHT = 2;
   */
  LIGHT = 2,
  /**
   * @generated from protobuf enum value: ANDROID_THERMAL_STATE_MODERATE = 3;
   */
  MODERATE = 3,
  /**
   * @generated from protobuf enum value: ANDROID_THERMAL_STATE_SEVERE = 4;
   */
  SEVERE = 4,
  /**
   * @generated from protobuf enum value: ANDROID_THERMAL_STATE_CRITICAL = 5;
   */
  CRITICAL = 5,
  /**
   * @generated from protobuf enum value: ANDROID_THERMAL_STATE_EMERGENCY = 6;
   */
  EMERGENCY = 6,
  /**
   * @generated from protobuf enum value: ANDROID_THERMAL_STATE_SHUTDOWN = 7;
   */
  SHUTDOWN = 7,
}
/**
 * AppleThermalState is the thermal state as reported by Apple devices when available or applicable to the platform.
 * The full list of states (enum) is available here: https://developer.apple.com/documentation/foundation/processinfo/thermalstate
 *
 * @generated from protobuf enum stream.video.sfu.models.AppleThermalState
 */
export enum AppleThermalState {
  /**
   * @generated from protobuf enum value: APPLE_THERMAL_STATE_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,
  /**
   * @generated from protobuf enum value: APPLE_THERMAL_STATE_NOMINAL = 1;
   */
  NOMINAL = 1,
  /**
   * @generated from protobuf enum value: APPLE_THERMAL_STATE_FAIR = 2;
   */
  FAIR = 2,
  /**
   * @generated from protobuf enum value: APPLE_THERMAL_STATE_SERIOUS = 3;
   */
  SERIOUS = 3,
  /**
   * @generated from protobuf enum value: APPLE_THERMAL_STATE_CRITICAL = 4;
   */
  CRITICAL = 4,
}
// @generated message type with reflection information, may provide speed optimized methods
class CallState$Type extends MessageType<CallState> {
  constructor() {
    super('stream.video.sfu.models.CallState', [
      {
        no: 1,
        name: 'participants',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => Participant,
      },
      { no: 2, name: 'started_at', kind: 'message', T: () => Timestamp },
      {
        no: 3,
        name: 'participant_count',
        kind: 'message',
        T: () => ParticipantCount,
      },
      {
        no: 4,
        name: 'pins',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => Pin,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message stream.video.sfu.models.CallState
 */
export const CallState = new CallState$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ParticipantCount$Type extends MessageType<ParticipantCount> {
  constructor() {
    super('stream.video.sfu.models.ParticipantCount', [
      { no: 1, name: 'total', kind: 'scalar', T: 13 /*ScalarType.UINT32*/ },
      { no: 2, name: 'anonymous', kind: 'scalar', T: 13 /*ScalarType.UINT32*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message stream.video.sfu.models.ParticipantCount
 */
export const ParticipantCount = new ParticipantCount$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Pin$Type extends MessageType<Pin> {
  constructor() {
    super('stream.video.sfu.models.Pin', [
      { no: 1, name: 'user_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'session_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message stream.video.sfu.models.Pin
 */
export const Pin = new Pin$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Participant$Type extends MessageType<Participant> {
  constructor() {
    super('stream.video.sfu.models.Participant', [
      { no: 1, name: 'user_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'session_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 3,
        name: 'published_tracks',
        kind: 'enum',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => [
          'stream.video.sfu.models.TrackType',
          TrackType,
          'TRACK_TYPE_',
        ],
      },
      { no: 4, name: 'joined_at', kind: 'message', T: () => Timestamp },
      {
        no: 5,
        name: 'track_lookup_prefix',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 6,
        name: 'connection_quality',
        kind: 'enum',
        T: () => [
          'stream.video.sfu.models.ConnectionQuality',
          ConnectionQuality,
          'CONNECTION_QUALITY_',
        ],
      },
      { no: 7, name: 'is_speaking', kind: 'scalar', T: 8 /*ScalarType.BOOL*/ },
      {
        no: 8,
        name: 'is_dominant_speaker',
        kind: 'scalar',
        T: 8 /*ScalarType.BOOL*/,
      },
      { no: 9, name: 'audio_level', kind: 'scalar', T: 2 /*ScalarType.FLOAT*/ },
      { no: 10, name: 'name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 11, name: 'image', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 12, name: 'custom', kind: 'message', T: () => Struct },
      {
        no: 13,
        name: 'roles',
        kind: 'scalar',
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message stream.video.sfu.models.Participant
 */
export const Participant = new Participant$Type();
// @generated message type with reflection information, may provide speed optimized methods
class StreamQuality$Type extends MessageType<StreamQuality> {
  constructor() {
    super('stream.video.sfu.models.StreamQuality', [
      {
        no: 1,
        name: 'video_quality',
        kind: 'enum',
        T: () => [
          'stream.video.sfu.models.VideoQuality',
          VideoQuality,
          'VIDEO_QUALITY_',
        ],
      },
      { no: 2, name: 'user_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message stream.video.sfu.models.StreamQuality
 */
export const StreamQuality = new StreamQuality$Type();
// @generated message type with reflection information, may provide speed optimized methods
class VideoDimension$Type extends MessageType<VideoDimension> {
  constructor() {
    super('stream.video.sfu.models.VideoDimension', [
      { no: 1, name: 'width', kind: 'scalar', T: 13 /*ScalarType.UINT32*/ },
      { no: 2, name: 'height', kind: 'scalar', T: 13 /*ScalarType.UINT32*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message stream.video.sfu.models.VideoDimension
 */
export const VideoDimension = new VideoDimension$Type();
// @generated message type with reflection information, may provide speed optimized methods
class VideoLayer$Type extends MessageType<VideoLayer> {
  constructor() {
    super('stream.video.sfu.models.VideoLayer', [
      { no: 1, name: 'rid', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 2,
        name: 'video_dimension',
        kind: 'message',
        T: () => VideoDimension,
      },
      { no: 4, name: 'bitrate', kind: 'scalar', T: 13 /*ScalarType.UINT32*/ },
      { no: 5, name: 'fps', kind: 'scalar', T: 13 /*ScalarType.UINT32*/ },
      {
        no: 6,
        name: 'quality',
        kind: 'enum',
        T: () => [
          'stream.video.sfu.models.VideoQuality',
          VideoQuality,
          'VIDEO_QUALITY_',
        ],
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message stream.video.sfu.models.VideoLayer
 */
export const VideoLayer = new VideoLayer$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PublishOption$Type extends MessageType<PublishOption> {
  constructor() {
    super('stream.video.sfu.models.PublishOption', [
      {
        no: 1,
        name: 'track_type',
        kind: 'enum',
        T: () => [
          'stream.video.sfu.models.TrackType',
          TrackType,
          'TRACK_TYPE_',
        ],
      },
      { no: 2, name: 'codec', kind: 'message', T: () => Codec },
      { no: 3, name: 'bitrate', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      { no: 4, name: 'fps', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      {
        no: 5,
        name: 'max_spatial_layers',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      {
        no: 6,
        name: 'max_temporal_layers',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message stream.video.sfu.models.PublishOption
 */
export const PublishOption = new PublishOption$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Codec$Type extends MessageType<Codec> {
  constructor() {
    super('stream.video.sfu.models.Codec', [
      {
        no: 16,
        name: 'payload_type',
        kind: 'scalar',
        T: 13 /*ScalarType.UINT32*/,
      },
      { no: 10, name: 'name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 14,
        name: 'clock_rate',
        kind: 'scalar',
        T: 13 /*ScalarType.UINT32*/,
      },
      {
        no: 15,
        name: 'encoding_parameters',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      { no: 12, name: 'fmtp', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message stream.video.sfu.models.Codec
 */
export const Codec = new Codec$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ICETrickle$Type extends MessageType<ICETrickle> {
  constructor() {
    super('stream.video.sfu.models.ICETrickle', [
      {
        no: 1,
        name: 'peer_type',
        kind: 'enum',
        T: () => ['stream.video.sfu.models.PeerType', PeerType, 'PEER_TYPE_'],
      },
      {
        no: 2,
        name: 'ice_candidate',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      { no: 3, name: 'session_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message stream.video.sfu.models.ICETrickle
 */
export const ICETrickle = new ICETrickle$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TrackInfo$Type extends MessageType<TrackInfo> {
  constructor() {
    super('stream.video.sfu.models.TrackInfo', [
      { no: 1, name: 'track_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 2,
        name: 'track_type',
        kind: 'enum',
        T: () => [
          'stream.video.sfu.models.TrackType',
          TrackType,
          'TRACK_TYPE_',
        ],
      },
      {
        no: 5,
        name: 'layers',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => VideoLayer,
      },
      { no: 6, name: 'mid', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 7, name: 'dtx', kind: 'scalar', T: 8 /*ScalarType.BOOL*/ },
      { no: 8, name: 'stereo', kind: 'scalar', T: 8 /*ScalarType.BOOL*/ },
      { no: 9, name: 'red', kind: 'scalar', T: 8 /*ScalarType.BOOL*/ },
      { no: 10, name: 'muted', kind: 'scalar', T: 8 /*ScalarType.BOOL*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message stream.video.sfu.models.TrackInfo
 */
export const TrackInfo = new TrackInfo$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Error$Type extends MessageType<Error> {
  constructor() {
    super('stream.video.sfu.models.Error', [
      {
        no: 1,
        name: 'code',
        kind: 'enum',
        T: () => [
          'stream.video.sfu.models.ErrorCode',
          ErrorCode,
          'ERROR_CODE_',
        ],
      },
      { no: 2, name: 'message', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 3, name: 'should_retry', kind: 'scalar', T: 8 /*ScalarType.BOOL*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message stream.video.sfu.models.Error
 */
export const Error = new Error$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ClientDetails$Type extends MessageType<ClientDetails> {
  constructor() {
    super('stream.video.sfu.models.ClientDetails', [
      { no: 1, name: 'sdk', kind: 'message', T: () => Sdk },
      { no: 2, name: 'os', kind: 'message', T: () => OS },
      { no: 3, name: 'browser', kind: 'message', T: () => Browser },
      { no: 4, name: 'device', kind: 'message', T: () => Device },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message stream.video.sfu.models.ClientDetails
 */
export const ClientDetails = new ClientDetails$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Sdk$Type extends MessageType<Sdk> {
  constructor() {
    super('stream.video.sfu.models.Sdk', [
      {
        no: 1,
        name: 'type',
        kind: 'enum',
        T: () => ['stream.video.sfu.models.SdkType', SdkType, 'SDK_TYPE_'],
      },
      { no: 2, name: 'major', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 3, name: 'minor', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 4, name: 'patch', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message stream.video.sfu.models.Sdk
 */
export const Sdk = new Sdk$Type();
// @generated message type with reflection information, may provide speed optimized methods
class OS$Type extends MessageType<OS> {
  constructor() {
    super('stream.video.sfu.models.OS', [
      { no: 1, name: 'name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'version', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 3,
        name: 'architecture',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message stream.video.sfu.models.OS
 */
export const OS = new OS$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Browser$Type extends MessageType<Browser> {
  constructor() {
    super('stream.video.sfu.models.Browser', [
      { no: 1, name: 'name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'version', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message stream.video.sfu.models.Browser
 */
export const Browser = new Browser$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Device$Type extends MessageType<Device> {
  constructor() {
    super('stream.video.sfu.models.Device', [
      { no: 1, name: 'name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'version', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message stream.video.sfu.models.Device
 */
export const Device = new Device$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Call$Type extends MessageType<Call> {
  constructor() {
    super('stream.video.sfu.models.Call', [
      { no: 1, name: 'type', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 3,
        name: 'created_by_user_id',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 4,
        name: 'host_user_id',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      { no: 5, name: 'custom', kind: 'message', T: () => Struct },
      { no: 6, name: 'created_at', kind: 'message', T: () => Timestamp },
      { no: 7, name: 'updated_at', kind: 'message', T: () => Timestamp },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message stream.video.sfu.models.Call
 */
export const Call = new Call$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CallGrants$Type extends MessageType<CallGrants> {
  constructor() {
    super('stream.video.sfu.models.CallGrants', [
      {
        no: 1,
        name: 'can_publish_audio',
        kind: 'scalar',
        T: 8 /*ScalarType.BOOL*/,
      },
      {
        no: 2,
        name: 'can_publish_video',
        kind: 'scalar',
        T: 8 /*ScalarType.BOOL*/,
      },
      {
        no: 3,
        name: 'can_screenshare',
        kind: 'scalar',
        T: 8 /*ScalarType.BOOL*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message stream.video.sfu.models.CallGrants
 */
export const CallGrants = new CallGrants$Type();
// @generated message type with reflection information, may provide speed optimized methods
class InputDevices$Type extends MessageType<InputDevices> {
  constructor() {
    super('stream.video.sfu.models.InputDevices', [
      {
        no: 1,
        name: 'available_devices',
        kind: 'scalar',
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 2,
        name: 'current_device',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      { no: 3, name: 'is_permitted', kind: 'scalar', T: 8 /*ScalarType.BOOL*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message stream.video.sfu.models.InputDevices
 */
export const InputDevices = new InputDevices$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AndroidState$Type extends MessageType<AndroidState> {
  constructor() {
    super('stream.video.sfu.models.AndroidState', [
      {
        no: 1,
        name: 'thermal_state',
        kind: 'enum',
        T: () => [
          'stream.video.sfu.models.AndroidThermalState',
          AndroidThermalState,
          'ANDROID_THERMAL_STATE_',
        ],
      },
      {
        no: 2,
        name: 'is_power_saver_mode',
        kind: 'scalar',
        T: 8 /*ScalarType.BOOL*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message stream.video.sfu.models.AndroidState
 */
export const AndroidState = new AndroidState$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AppleState$Type extends MessageType<AppleState> {
  constructor() {
    super('stream.video.sfu.models.AppleState', [
      {
        no: 1,
        name: 'thermal_state',
        kind: 'enum',
        T: () => [
          'stream.video.sfu.models.AppleThermalState',
          AppleThermalState,
          'APPLE_THERMAL_STATE_',
        ],
      },
      {
        no: 2,
        name: 'is_low_power_mode_enabled',
        kind: 'scalar',
        T: 8 /*ScalarType.BOOL*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message stream.video.sfu.models.AppleState
 */
export const AppleState = new AppleState$Type();
