/* eslint-disable */
// @generated by protobuf-ts 2.9.4 with parameter long_type_string,client_generic,server_none,eslint_disable,optimize_code_size
// @generated from protobuf file "video/sfu/event/events.proto" (package "stream.video.sfu.event", syntax proto3)
// tslint:disable
import { MessageType } from '@protobuf-ts/runtime';
import { CallEndedReason } from '../models/models';
import { GoAwayReason } from '../models/models';
import { CallGrants } from '../models/models';
import { Codec } from '../models/models';
import { ConnectionQuality } from '../models/models';
import { CallState } from '../models/models';
import { TrackSubscriptionDetails } from '../signal_rpc/signal';
import { TrackInfo } from '../models/models';
import { ClientDetails } from '../models/models';
import { TrackUnpublishReason } from '../models/models';
import { Participant } from '../models/models';
import { TrackType } from '../models/models';
import { ParticipantCount } from '../models/models';
import { PeerType } from '../models/models';
import { WebsocketReconnectStrategy } from '../models/models';
import { Error as Error$ } from '../models/models';
import { Pin } from '../models/models';
import { PublishOption } from '../models/models';
import { ICETrickle as ICETrickle$ } from '../models/models';
/**
 * SFUEvent is a message that is sent from the SFU to the client.
 *
 * @generated from protobuf message stream.video.sfu.event.SfuEvent
 */
export interface SfuEvent {
  /**
   * @generated from protobuf oneof: event_payload
   */
  eventPayload:
    | {
        oneofKind: 'subscriberOffer';
        /**
         * SubscriberOffer sends the SDP offer for establishing the
         * subscriber PeerConnection.
         *
         * @generated from protobuf field: stream.video.sfu.event.SubscriberOffer subscriber_offer = 1;
         */
        subscriberOffer: SubscriberOffer;
      }
    | {
        oneofKind: 'publisherAnswer';
        /**
         * PublisherAnswer sends the SDP answer to the offer sent by
         * the client for establishing the Publisher PeerConnection.
         *
         * @generated from protobuf field: stream.video.sfu.event.PublisherAnswer publisher_answer = 2;
         */
        publisherAnswer: PublisherAnswer;
      }
    | {
        oneofKind: 'connectionQualityChanged';
        /**
         * ConnectionQualityChanged is sent to inform the connection
         * quality of the participants in the call. It does not have
         * to contain the full list of call participants in it.
         *
         * @generated from protobuf field: stream.video.sfu.event.ConnectionQualityChanged connection_quality_changed = 3;
         */
        connectionQualityChanged: ConnectionQualityChanged;
      }
    | {
        oneofKind: 'audioLevelChanged';
        /**
         * AudioLevelChanged is sent for change in audio levels of
         * the participants.
         *
         * @generated from protobuf field: stream.video.sfu.event.AudioLevelChanged audio_level_changed = 4;
         */
        audioLevelChanged: AudioLevelChanged;
      }
    | {
        oneofKind: 'iceTrickle';
        /**
         * ICETrickle contains the ICE candidate required to establish
         * the ICE transport: part of establishing the PeerConnection
         * and also for ICE restarts.
         *
         * @generated from protobuf field: stream.video.sfu.models.ICETrickle ice_trickle = 5;
         */
        iceTrickle: ICETrickle$;
      }
    | {
        oneofKind: 'changePublishQuality';
        /**
         * ChangePublishQuality advises the publisher to switch on/off
         * various qualities of their video stream based on the subscription.
         * This is done to save the bandwidth and the CPU of the publisher.
         *
         * @generated from protobuf field: stream.video.sfu.event.ChangePublishQuality change_publish_quality = 6;
         */
        changePublishQuality: ChangePublishQuality;
      }
    | {
        oneofKind: 'participantJoined';
        /**
         * ParticipantJoined notifies the client that a new participant
         * has joined the call. This is not sent for anonymous users.
         *
         * @generated from protobuf field: stream.video.sfu.event.ParticipantJoined participant_joined = 10;
         */
        participantJoined: ParticipantJoined;
      }
    | {
        oneofKind: 'participantLeft';
        /**
         * ParticipantLeft notifies the client that a call participant
         * has left the call. This is not sent for anonymous users.
         *
         * @generated from protobuf field: stream.video.sfu.event.ParticipantLeft participant_left = 11;
         */
        participantLeft: ParticipantLeft;
      }
    | {
        oneofKind: 'dominantSpeakerChanged';
        /**
         * DominantSpeakerChanged notifies the client about the current
         * dominant speaker. This is required for certain use cases like
         * the spotlight view.
         *
         * @generated from protobuf field: stream.video.sfu.event.DominantSpeakerChanged dominant_speaker_changed = 12;
         */
        dominantSpeakerChanged: DominantSpeakerChanged;
      }
    | {
        oneofKind: 'joinResponse';
        /**
         * JoinResponse acknowledges a participant successfully joining
         * the call. This is sent in response to the JoinRequest.
         *
         * @generated from protobuf field: stream.video.sfu.event.JoinResponse join_response = 13;
         */
        joinResponse: JoinResponse;
      }
    | {
        oneofKind: 'healthCheckResponse';
        /**
         * HealthCheckResponse is sent in response to the HealthCheckRequest.
         * It contains the participant count in the call.
         *
         * @generated from protobuf field: stream.video.sfu.event.HealthCheckResponse health_check_response = 14;
         */
        healthCheckResponse: HealthCheckResponse;
      }
    | {
        oneofKind: 'trackPublished';
        /**
         * TrackPublished is sent when a new track (like audio, video, screenshare)
         * is published by a participant in the call. It is also sent on mute/unmute.
         *
         * @generated from protobuf field: stream.video.sfu.event.TrackPublished track_published = 16;
         */
        trackPublished: TrackPublished;
      }
    | {
        oneofKind: 'trackUnpublished';
        /**
         * TrackUnpublished is sent when a track (like audio, video, screenshare)
         * is no longer published. It is sent on muting a track or when the participant
         * is leaving the call.
         *
         * @generated from protobuf field: stream.video.sfu.event.TrackUnpublished track_unpublished = 17;
         */
        trackUnpublished: TrackUnpublished;
      }
    | {
        oneofKind: 'error';
        /**
         * Error is used to communicate any error related to the participant. The
         * error code and the message explain what went wrong. Whether the participant
         * can retry is also indicated.
         *
         * @generated from protobuf field: stream.video.sfu.event.Error error = 18;
         */
        error: Error;
      }
    | {
        oneofKind: 'callGrantsUpdated';
        /**
         * CallGrantsUpdated tells what tracks a participant is allowed to publish.
         *
         * @generated from protobuf field: stream.video.sfu.event.CallGrantsUpdated call_grants_updated = 19;
         */
        callGrantsUpdated: CallGrantsUpdated;
      }
    | {
        oneofKind: 'goAway';
        /**
         * GoAway tells the client to migrate away from the SFU it is connected to.
         * The reason field indicates why this message was sent.
         *
         * @generated from protobuf field: stream.video.sfu.event.GoAway go_away = 20;
         */
        goAway: GoAway;
      }
    | {
        oneofKind: 'iceRestart';
        /**
         * ICERestart tells the client to perform ICE restart.
         *
         * @generated from protobuf field: stream.video.sfu.event.ICERestart ice_restart = 21;
         */
        iceRestart: ICERestart;
      }
    | {
        oneofKind: 'pinsUpdated';
        /**
         * PinsChanged is sent the list of pins in the call changes. This event contains the entire list of pins.
         *
         * @generated from protobuf field: stream.video.sfu.event.PinsChanged pins_updated = 22;
         */
        pinsUpdated: PinsChanged;
      }
    | {
        oneofKind: 'callEnded';
        /**
         * CallEnded is sent by the SFU to the client to signal that the call has ended.
         * The reason may specify why the call has ended.
         *
         * @generated from protobuf field: stream.video.sfu.event.CallEnded call_ended = 23;
         */
        callEnded: CallEnded;
      }
    | {
        oneofKind: 'participantUpdated';
        /**
         * ParticipantUpdated is sent when user data is updated
         *
         * @generated from protobuf field: stream.video.sfu.event.ParticipantUpdated participant_updated = 24;
         */
        participantUpdated: ParticipantUpdated;
      }
    | {
        oneofKind: 'participantMigrationComplete';
        /**
         * ParticipantMigrationComplete is sent when the participant migration is complete
         *
         * @generated from protobuf field: stream.video.sfu.event.ParticipantMigrationComplete participant_migration_complete = 25;
         */
        participantMigrationComplete: ParticipantMigrationComplete;
      }
    | {
        oneofKind: 'codecNegotiationComplete';
        /**
         * CodecNegotiationComplete is sent to signal the completion of a codec negotiation.
         * SDKs can safely stop previous transceivers
         *
         * @generated from protobuf field: stream.video.sfu.event.CodecNegotiationComplete codec_negotiation_complete = 26;
         */
        codecNegotiationComplete: CodecNegotiationComplete;
      }
    | {
        oneofKind: 'changePublishOptions';
        /**
         * ChangePublishOptions is sent to signal the change in publish options such as a new codec or simulcast layers
         *
         * @generated from protobuf field: stream.video.sfu.event.ChangePublishOptions change_publish_options = 27;
         */
        changePublishOptions: ChangePublishOptions;
      }
    | {
        oneofKind: undefined;
      };
}
/**
 * @generated from protobuf message stream.video.sfu.event.ChangePublishOptions
 */
export interface ChangePublishOptions {
  /**
   * @generated from protobuf field: stream.video.sfu.models.PublishOption publish_option = 1;
   */
  publishOption?: PublishOption;
}
/**
 * @generated from protobuf message stream.video.sfu.event.CodecNegotiationComplete
 */
export interface CodecNegotiationComplete {}
/**
 * @generated from protobuf message stream.video.sfu.event.ParticipantMigrationComplete
 */
export interface ParticipantMigrationComplete {}
/**
 * @generated from protobuf message stream.video.sfu.event.PinsChanged
 */
export interface PinsChanged {
  /**
   * the list of pins in the call.
   * Pins are ordered in descending order (most important first).
   *
   * @generated from protobuf field: repeated stream.video.sfu.models.Pin pins = 1;
   */
  pins: Pin[];
}
/**
 * @generated from protobuf message stream.video.sfu.event.Error
 */
export interface Error {
  /**
   * @generated from protobuf field: stream.video.sfu.models.Error error = 4;
   */
  error?: Error$;
  /**
   * returns the reconnect strategy to be used by the client
   *
   * @generated from protobuf field: stream.video.sfu.models.WebsocketReconnectStrategy reconnect_strategy = 5;
   */
  reconnectStrategy: WebsocketReconnectStrategy;
}
/**
 * @generated from protobuf message stream.video.sfu.event.ICETrickle
 */
export interface ICETrickle {
  /**
   * @generated from protobuf field: stream.video.sfu.models.PeerType peer_type = 1;
   */
  peerType: PeerType;
  /**
   * @generated from protobuf field: string ice_candidate = 2;
   */
  iceCandidate: string;
}
/**
 * @generated from protobuf message stream.video.sfu.event.ICERestart
 */
export interface ICERestart {
  /**
   * @generated from protobuf field: stream.video.sfu.models.PeerType peer_type = 1;
   */
  peerType: PeerType;
}
/**
 * SfuRequest is a message that is sent from the client to the SFU.
 *
 * @generated from protobuf message stream.video.sfu.event.SfuRequest
 */
export interface SfuRequest {
  /**
   * @generated from protobuf oneof: request_payload
   */
  requestPayload:
    | {
        oneofKind: 'joinRequest';
        /**
         * @generated from protobuf field: stream.video.sfu.event.JoinRequest join_request = 1;
         */
        joinRequest: JoinRequest;
      }
    | {
        oneofKind: 'healthCheckRequest';
        /**
         * @generated from protobuf field: stream.video.sfu.event.HealthCheckRequest health_check_request = 2;
         */
        healthCheckRequest: HealthCheckRequest;
      }
    | {
        oneofKind: 'leaveCallRequest';
        /**
         * @generated from protobuf field: stream.video.sfu.event.LeaveCallRequest leave_call_request = 3;
         */
        leaveCallRequest: LeaveCallRequest;
      }
    | {
        oneofKind: undefined;
      };
}
/**
 * @generated from protobuf message stream.video.sfu.event.LeaveCallRequest
 */
export interface LeaveCallRequest {
  /**
   * @generated from protobuf field: string session_id = 1;
   */
  sessionId: string;
  /**
   * @generated from protobuf field: string reason = 2;
   */
  reason: string;
}
/**
 * @generated from protobuf message stream.video.sfu.event.HealthCheckRequest
 */
export interface HealthCheckRequest {}
/**
 * @generated from protobuf message stream.video.sfu.event.HealthCheckResponse
 */
export interface HealthCheckResponse {
  /**
   * @generated from protobuf field: stream.video.sfu.models.ParticipantCount participant_count = 1;
   */
  participantCount?: ParticipantCount;
}
/**
 * @generated from protobuf message stream.video.sfu.event.TrackPublished
 */
export interface TrackPublished {
  /**
   * @generated from protobuf field: string user_id = 1;
   */
  userId: string;
  /**
   * @generated from protobuf field: string session_id = 2;
   */
  sessionId: string;
  /**
   * @generated from protobuf field: stream.video.sfu.models.TrackType type = 3;
   */
  type: TrackType;
  /**
   * Participant information here is included as an optimisation
   * for large calls where broadcasting on every participant join
   * is expensive. You should **NOT** assume that this will always
   * be available though you can use it to update the participant
   * list when it is there.
   *
   * @generated from protobuf field: stream.video.sfu.models.Participant participant = 4;
   */
  participant?: Participant;
}
/**
 * @generated from protobuf message stream.video.sfu.event.TrackUnpublished
 */
export interface TrackUnpublished {
  /**
   * @generated from protobuf field: string user_id = 1;
   */
  userId: string;
  /**
   * @generated from protobuf field: string session_id = 2;
   */
  sessionId: string;
  /**
   * @generated from protobuf field: stream.video.sfu.models.TrackType type = 3;
   */
  type: TrackType;
  /**
   * @generated from protobuf field: stream.video.sfu.models.TrackUnpublishReason cause = 4;
   */
  cause: TrackUnpublishReason;
  /**
   * Participant information here is included as an optimisation
   * for large calls where broadcasting on every participant join
   * is expensive. You should **NOT** assume that this will always
   * be available though you can use it to update the participant
   * list when it is there.
   *
   * @generated from protobuf field: stream.video.sfu.models.Participant participant = 5;
   */
  participant?: Participant;
}
/**
 * @generated from protobuf message stream.video.sfu.event.JoinRequest
 */
export interface JoinRequest {
  /**
   * @generated from protobuf field: string token = 1;
   */
  token: string;
  /**
   * @generated from protobuf field: string session_id = 2;
   */
  sessionId: string;
  /**
   * dumb SDP that allow us to extract subscriber's decode codecs
   *
   * @generated from protobuf field: string subscriber_sdp = 3;
   */
  subscriberSdp: string;
  /**
   * @generated from protobuf field: string publisher_sdp = 8;
   */
  publisherSdp: string;
  /**
   * @generated from protobuf field: stream.video.sfu.models.ClientDetails client_details = 4;
   */
  clientDetails?: ClientDetails;
  /**
   * Deprecated: use ReconnectDetails instead
   *
   * @deprecated
   * @generated from protobuf field: stream.video.sfu.event.Migration migration = 5 [deprecated = true];
   */
  migration?: Migration;
  /**
   * Fast reconnect flag explicitly indicates that if the participant session
   * and the associated state is still present in the SFU, the client is ready
   * to restore the PeerConnection with an ICE restart. If the SFU replies with
   * "reconnected: true" in its JoinResponse, then it is safe to perform an ICE
   * restart or else the existing PeerConnections must be cleaned up.
   *
   * For the SFU, fast_reconnect:false indicates that even if it has the state
   * cached, the client state is not in sync and hence it must be cleaned up before
   * proceeding further.
   *
   * @deprecated
   * @generated from protobuf field: bool fast_reconnect = 6 [deprecated = true];
   */
  fastReconnect: boolean;
  /**
   * @generated from protobuf field: stream.video.sfu.event.ReconnectDetails reconnect_details = 7;
   */
  reconnectDetails?: ReconnectDetails;
  /**
   * @generated from protobuf field: repeated stream.video.sfu.models.PublishOption preferred_publish_options = 9;
   */
  preferredPublishOptions: PublishOption[];
}
/**
 * @generated from protobuf message stream.video.sfu.event.ReconnectDetails
 */
export interface ReconnectDetails {
  /**
   * @generated from protobuf field: stream.video.sfu.models.WebsocketReconnectStrategy strategy = 1;
   */
  strategy: WebsocketReconnectStrategy;
  /**
   * @generated from protobuf field: repeated stream.video.sfu.models.TrackInfo announced_tracks = 3;
   */
  announcedTracks: TrackInfo[];
  /**
   * @generated from protobuf field: repeated stream.video.sfu.signal.TrackSubscriptionDetails subscriptions = 4;
   */
  subscriptions: TrackSubscriptionDetails[];
  /**
   * @generated from protobuf field: uint32 reconnect_attempt = 5;
   */
  reconnectAttempt: number;
  /**
   * @generated from protobuf field: string from_sfu_id = 6;
   */
  fromSfuId: string;
  /**
   * only set in case of rejoin
   *
   * @generated from protobuf field: string previous_session_id = 7;
   */
  previousSessionId: string;
}
/**
 * @generated from protobuf message stream.video.sfu.event.Migration
 */
export interface Migration {
  /**
   * @generated from protobuf field: string from_sfu_id = 1;
   */
  fromSfuId: string;
  /**
   * @generated from protobuf field: repeated stream.video.sfu.models.TrackInfo announced_tracks = 2;
   */
  announcedTracks: TrackInfo[];
  /**
   * @generated from protobuf field: repeated stream.video.sfu.signal.TrackSubscriptionDetails subscriptions = 3;
   */
  subscriptions: TrackSubscriptionDetails[];
}
/**
 * @generated from protobuf message stream.video.sfu.event.JoinResponse
 */
export interface JoinResponse {
  /**
   * @generated from protobuf field: stream.video.sfu.models.CallState call_state = 1;
   */
  callState?: CallState;
  /**
   * @generated from protobuf field: bool reconnected = 2;
   */
  reconnected: boolean;
  /**
   * @generated from protobuf field: int32 fast_reconnect_deadline_seconds = 3;
   */
  fastReconnectDeadlineSeconds: number;
  /**
   * @generated from protobuf field: repeated stream.video.sfu.models.PublishOption publish_options = 4;
   */
  publishOptions: PublishOption[];
}
/**
 * ParticipantJoined is fired when a user joins a call
 *
 * @generated from protobuf message stream.video.sfu.event.ParticipantJoined
 */
export interface ParticipantJoined {
  /**
   * @generated from protobuf field: string call_cid = 1;
   */
  callCid: string;
  /**
   * @generated from protobuf field: stream.video.sfu.models.Participant participant = 2;
   */
  participant?: Participant;
}
/**
 * ParticipantJoined is fired when a user leaves a call
 *
 * @generated from protobuf message stream.video.sfu.event.ParticipantLeft
 */
export interface ParticipantLeft {
  /**
   * @generated from protobuf field: string call_cid = 1;
   */
  callCid: string;
  /**
   * @generated from protobuf field: stream.video.sfu.models.Participant participant = 2;
   */
  participant?: Participant;
}
/**
 * ParticipantUpdated is fired when user data is updated
 *
 * @generated from protobuf message stream.video.sfu.event.ParticipantUpdated
 */
export interface ParticipantUpdated {
  /**
   * @generated from protobuf field: string call_cid = 1;
   */
  callCid: string;
  /**
   * @generated from protobuf field: stream.video.sfu.models.Participant participant = 2;
   */
  participant?: Participant;
}
/**
 * SubscriberOffer is sent when the SFU adds tracks to a subscription
 *
 * @generated from protobuf message stream.video.sfu.event.SubscriberOffer
 */
export interface SubscriberOffer {
  /**
   * @generated from protobuf field: bool ice_restart = 1;
   */
  iceRestart: boolean;
  /**
   * @generated from protobuf field: string sdp = 2;
   */
  sdp: string;
}
/**
 * @generated from protobuf message stream.video.sfu.event.PublisherAnswer
 */
export interface PublisherAnswer {
  /**
   * @generated from protobuf field: string sdp = 1;
   */
  sdp: string;
}
/**
 * ConnectionQuality is sent to inform about connection quality changes
 * eg. thierry's connection is not good -> render a red icon Zoom style
 *
 * @generated from protobuf message stream.video.sfu.event.ConnectionQualityChanged
 */
export interface ConnectionQualityChanged {
  /**
   * @generated from protobuf field: repeated stream.video.sfu.event.ConnectionQualityInfo connection_quality_updates = 1;
   */
  connectionQualityUpdates: ConnectionQualityInfo[];
}
/**
 * @generated from protobuf message stream.video.sfu.event.ConnectionQualityInfo
 */
export interface ConnectionQualityInfo {
  /**
   * @generated from protobuf field: string user_id = 1;
   */
  userId: string;
  /**
   * @generated from protobuf field: string session_id = 2;
   */
  sessionId: string;
  /**
   * @generated from protobuf field: stream.video.sfu.models.ConnectionQuality connection_quality = 3;
   */
  connectionQuality: ConnectionQuality;
}
/**
 * DominantSpeakerChanged is sent by the SFU to notify when there is a new dominant speaker in the call
 *
 * @generated from protobuf message stream.video.sfu.event.DominantSpeakerChanged
 */
export interface DominantSpeakerChanged {
  /**
   * @generated from protobuf field: string user_id = 1;
   */
  userId: string;
  /**
   * @generated from protobuf field: string session_id = 2;
   */
  sessionId: string;
}
/**
 * @generated from protobuf message stream.video.sfu.event.AudioLevel
 */
export interface AudioLevel {
  /**
   * @generated from protobuf field: string user_id = 1;
   */
  userId: string;
  /**
   * @generated from protobuf field: string session_id = 2;
   */
  sessionId: string;
  /**
   * 0.0 means complete silence, 1.0 loudest
   *
   * @generated from protobuf field: float level = 3;
   */
  level: number;
  /**
   * @generated from protobuf field: bool is_speaking = 4;
   */
  isSpeaking: boolean;
}
/**
 * AudioLevelChanged is sent by the SFU to notify about audio levels by user
 *
 * @generated from protobuf message stream.video.sfu.event.AudioLevelChanged
 */
export interface AudioLevelChanged {
  /**
   * @generated from protobuf field: repeated stream.video.sfu.event.AudioLevel audio_levels = 1;
   */
  audioLevels: AudioLevel[];
}
/**
 * @generated from protobuf message stream.video.sfu.event.AudioSender
 */
export interface AudioSender {
  /**
   * @generated from protobuf field: stream.video.sfu.models.Codec codec = 2;
   */
  codec?: Codec;
}
/**
 * VideoLayerSetting is used to specify various parameters of a particular encoding in simulcast.
 * The parameters are specified here - https://developer.mozilla.org/en-US/docs/Web/API/RTCRtpEncodingParameters
 * SDKs use these parameters sent from the server to dynamically adjust these parameters to save CPU, bandwidth
 *
 * @generated from protobuf message stream.video.sfu.event.VideoLayerSetting
 */
export interface VideoLayerSetting {
  /**
   * @generated from protobuf field: string name = 1;
   */
  name: string;
  /**
   * @generated from protobuf field: bool active = 2;
   */
  active: boolean;
  /**
   * @generated from protobuf field: int32 max_bitrate = 3;
   */
  maxBitrate: number;
  /**
   * @generated from protobuf field: float scale_resolution_down_by = 4;
   */
  scaleResolutionDownBy: number;
  /**
   * @generated from protobuf field: stream.video.sfu.models.Codec codec = 6;
   */
  codec?: Codec;
  /**
   * @generated from protobuf field: uint32 max_framerate = 7;
   */
  maxFramerate: number;
  /**
   * @generated from protobuf field: string scalability_mode = 8;
   */
  scalabilityMode: string;
}
/**
 * @generated from protobuf message stream.video.sfu.event.VideoSender
 */
export interface VideoSender {
  /**
   * @generated from protobuf field: stream.video.sfu.models.Codec codec = 2;
   */
  codec?: Codec;
  /**
   * @generated from protobuf field: repeated stream.video.sfu.event.VideoLayerSetting layers = 3;
   */
  layers: VideoLayerSetting[];
}
/**
 * sent to users when they need to change the quality of their video
 *
 * @generated from protobuf message stream.video.sfu.event.ChangePublishQuality
 */
export interface ChangePublishQuality {
  /**
   * @generated from protobuf field: repeated stream.video.sfu.event.AudioSender audio_senders = 1;
   */
  audioSenders: AudioSender[];
  /**
   * @generated from protobuf field: repeated stream.video.sfu.event.VideoSender video_senders = 2;
   */
  videoSenders: VideoSender[];
}
/**
 * CallGrants is sent to users when there is a change in the call permissions
 * to publish audio, video or share the screen for a user or a role. This is
 * idempotent and can be sent at any time. Only the current_grants in the latest
 * message should be considered. In other words, this is not dependent on any
 * other message (including previous call_grants_updated message)
 *
 * When this message is received, the client (say, React component) should mute
 * the tracks which are not allowed anymore. For example, if the message has
 * grants {audio:true, video:false} while the user is publishing video, then the
 * client component should mute the video track and gray out the video button.
 * This way, the user will not be able to publish video until the permissions
 * are granted again. Also, muting the track on the client side will prevent
 * the SFU from receiving the video track and will save bandwidth.
 *
 * In simple words, this is exactly the same as muting a track on the client.
 * Here, instead of the user instructing the client, SFU would be doing it.
 *
 * @generated from protobuf message stream.video.sfu.event.CallGrantsUpdated
 */
export interface CallGrantsUpdated {
  /**
   * @generated from protobuf field: stream.video.sfu.models.CallGrants current_grants = 1;
   */
  currentGrants?: CallGrants;
  /**
   * @generated from protobuf field: string message = 2;
   */
  message: string;
}
/**
 * Go away is sent by the SFU to the client to signal to migrate away from the SFU.
 * The evict reason may specify why the user is being evicted.
 *
 * @generated from protobuf message stream.video.sfu.event.GoAway
 */
export interface GoAway {
  /**
   * @generated from protobuf field: stream.video.sfu.models.GoAwayReason reason = 1;
   */
  reason: GoAwayReason;
}
/**
 * CallEnded is sent by the SFU to the client to signal that the call has ended.
 * The reason may specify why the call has ended.
 *
 * @generated from protobuf message stream.video.sfu.event.CallEnded
 */
export interface CallEnded {
  /**
   * @generated from protobuf field: stream.video.sfu.models.CallEndedReason reason = 1;
   */
  reason: CallEndedReason;
}
// @generated message type with reflection information, may provide speed optimized methods
class SfuEvent$Type extends MessageType<SfuEvent> {
  constructor() {
    super('stream.video.sfu.event.SfuEvent', [
      {
        no: 1,
        name: 'subscriber_offer',
        kind: 'message',
        oneof: 'eventPayload',
        T: () => SubscriberOffer,
      },
      {
        no: 2,
        name: 'publisher_answer',
        kind: 'message',
        oneof: 'eventPayload',
        T: () => PublisherAnswer,
      },
      {
        no: 3,
        name: 'connection_quality_changed',
        kind: 'message',
        oneof: 'eventPayload',
        T: () => ConnectionQualityChanged,
      },
      {
        no: 4,
        name: 'audio_level_changed',
        kind: 'message',
        oneof: 'eventPayload',
        T: () => AudioLevelChanged,
      },
      {
        no: 5,
        name: 'ice_trickle',
        kind: 'message',
        oneof: 'eventPayload',
        T: () => ICETrickle$,
      },
      {
        no: 6,
        name: 'change_publish_quality',
        kind: 'message',
        oneof: 'eventPayload',
        T: () => ChangePublishQuality,
      },
      {
        no: 10,
        name: 'participant_joined',
        kind: 'message',
        oneof: 'eventPayload',
        T: () => ParticipantJoined,
      },
      {
        no: 11,
        name: 'participant_left',
        kind: 'message',
        oneof: 'eventPayload',
        T: () => ParticipantLeft,
      },
      {
        no: 12,
        name: 'dominant_speaker_changed',
        kind: 'message',
        oneof: 'eventPayload',
        T: () => DominantSpeakerChanged,
      },
      {
        no: 13,
        name: 'join_response',
        kind: 'message',
        oneof: 'eventPayload',
        T: () => JoinResponse,
      },
      {
        no: 14,
        name: 'health_check_response',
        kind: 'message',
        oneof: 'eventPayload',
        T: () => HealthCheckResponse,
      },
      {
        no: 16,
        name: 'track_published',
        kind: 'message',
        oneof: 'eventPayload',
        T: () => TrackPublished,
      },
      {
        no: 17,
        name: 'track_unpublished',
        kind: 'message',
        oneof: 'eventPayload',
        T: () => TrackUnpublished,
      },
      {
        no: 18,
        name: 'error',
        kind: 'message',
        oneof: 'eventPayload',
        T: () => Error,
      },
      {
        no: 19,
        name: 'call_grants_updated',
        kind: 'message',
        oneof: 'eventPayload',
        T: () => CallGrantsUpdated,
      },
      {
        no: 20,
        name: 'go_away',
        kind: 'message',
        oneof: 'eventPayload',
        T: () => GoAway,
      },
      {
        no: 21,
        name: 'ice_restart',
        kind: 'message',
        oneof: 'eventPayload',
        T: () => ICERestart,
      },
      {
        no: 22,
        name: 'pins_updated',
        kind: 'message',
        oneof: 'eventPayload',
        T: () => PinsChanged,
      },
      {
        no: 23,
        name: 'call_ended',
        kind: 'message',
        oneof: 'eventPayload',
        T: () => CallEnded,
      },
      {
        no: 24,
        name: 'participant_updated',
        kind: 'message',
        oneof: 'eventPayload',
        T: () => ParticipantUpdated,
      },
      {
        no: 25,
        name: 'participant_migration_complete',
        kind: 'message',
        oneof: 'eventPayload',
        T: () => ParticipantMigrationComplete,
      },
      {
        no: 26,
        name: 'codec_negotiation_complete',
        kind: 'message',
        oneof: 'eventPayload',
        T: () => CodecNegotiationComplete,
      },
      {
        no: 27,
        name: 'change_publish_options',
        kind: 'message',
        oneof: 'eventPayload',
        T: () => ChangePublishOptions,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message stream.video.sfu.event.SfuEvent
 */
export const SfuEvent = new SfuEvent$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ChangePublishOptions$Type extends MessageType<ChangePublishOptions> {
  constructor() {
    super('stream.video.sfu.event.ChangePublishOptions', [
      {
        no: 1,
        name: 'publish_option',
        kind: 'message',
        T: () => PublishOption,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message stream.video.sfu.event.ChangePublishOptions
 */
export const ChangePublishOptions = new ChangePublishOptions$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CodecNegotiationComplete$Type extends MessageType<CodecNegotiationComplete> {
  constructor() {
    super('stream.video.sfu.event.CodecNegotiationComplete', []);
  }
}
/**
 * @generated MessageType for protobuf message stream.video.sfu.event.CodecNegotiationComplete
 */
export const CodecNegotiationComplete = new CodecNegotiationComplete$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ParticipantMigrationComplete$Type extends MessageType<ParticipantMigrationComplete> {
  constructor() {
    super('stream.video.sfu.event.ParticipantMigrationComplete', []);
  }
}
/**
 * @generated MessageType for protobuf message stream.video.sfu.event.ParticipantMigrationComplete
 */
export const ParticipantMigrationComplete =
  new ParticipantMigrationComplete$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PinsChanged$Type extends MessageType<PinsChanged> {
  constructor() {
    super('stream.video.sfu.event.PinsChanged', [
      {
        no: 1,
        name: 'pins',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => Pin,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message stream.video.sfu.event.PinsChanged
 */
export const PinsChanged = new PinsChanged$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Error$Type extends MessageType<Error> {
  constructor() {
    super('stream.video.sfu.event.Error', [
      { no: 4, name: 'error', kind: 'message', T: () => Error$ },
      {
        no: 5,
        name: 'reconnect_strategy',
        kind: 'enum',
        T: () => [
          'stream.video.sfu.models.WebsocketReconnectStrategy',
          WebsocketReconnectStrategy,
          'WEBSOCKET_RECONNECT_STRATEGY_',
        ],
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message stream.video.sfu.event.Error
 */
export const Error = new Error$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ICETrickle$Type extends MessageType<ICETrickle> {
  constructor() {
    super('stream.video.sfu.event.ICETrickle', [
      {
        no: 1,
        name: 'peer_type',
        kind: 'enum',
        T: () => ['stream.video.sfu.models.PeerType', PeerType, 'PEER_TYPE_'],
      },
      {
        no: 2,
        name: 'ice_candidate',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message stream.video.sfu.event.ICETrickle
 */
export const ICETrickle = new ICETrickle$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ICERestart$Type extends MessageType<ICERestart> {
  constructor() {
    super('stream.video.sfu.event.ICERestart', [
      {
        no: 1,
        name: 'peer_type',
        kind: 'enum',
        T: () => ['stream.video.sfu.models.PeerType', PeerType, 'PEER_TYPE_'],
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message stream.video.sfu.event.ICERestart
 */
export const ICERestart = new ICERestart$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SfuRequest$Type extends MessageType<SfuRequest> {
  constructor() {
    super('stream.video.sfu.event.SfuRequest', [
      {
        no: 1,
        name: 'join_request',
        kind: 'message',
        oneof: 'requestPayload',
        T: () => JoinRequest,
      },
      {
        no: 2,
        name: 'health_check_request',
        kind: 'message',
        oneof: 'requestPayload',
        T: () => HealthCheckRequest,
      },
      {
        no: 3,
        name: 'leave_call_request',
        kind: 'message',
        oneof: 'requestPayload',
        T: () => LeaveCallRequest,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message stream.video.sfu.event.SfuRequest
 */
export const SfuRequest = new SfuRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class LeaveCallRequest$Type extends MessageType<LeaveCallRequest> {
  constructor() {
    super('stream.video.sfu.event.LeaveCallRequest', [
      { no: 1, name: 'session_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'reason', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message stream.video.sfu.event.LeaveCallRequest
 */
export const LeaveCallRequest = new LeaveCallRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class HealthCheckRequest$Type extends MessageType<HealthCheckRequest> {
  constructor() {
    super('stream.video.sfu.event.HealthCheckRequest', []);
  }
}
/**
 * @generated MessageType for protobuf message stream.video.sfu.event.HealthCheckRequest
 */
export const HealthCheckRequest = new HealthCheckRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class HealthCheckResponse$Type extends MessageType<HealthCheckResponse> {
  constructor() {
    super('stream.video.sfu.event.HealthCheckResponse', [
      {
        no: 1,
        name: 'participant_count',
        kind: 'message',
        T: () => ParticipantCount,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message stream.video.sfu.event.HealthCheckResponse
 */
export const HealthCheckResponse = new HealthCheckResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TrackPublished$Type extends MessageType<TrackPublished> {
  constructor() {
    super('stream.video.sfu.event.TrackPublished', [
      { no: 1, name: 'user_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'session_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 3,
        name: 'type',
        kind: 'enum',
        T: () => [
          'stream.video.sfu.models.TrackType',
          TrackType,
          'TRACK_TYPE_',
        ],
      },
      { no: 4, name: 'participant', kind: 'message', T: () => Participant },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message stream.video.sfu.event.TrackPublished
 */
export const TrackPublished = new TrackPublished$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TrackUnpublished$Type extends MessageType<TrackUnpublished> {
  constructor() {
    super('stream.video.sfu.event.TrackUnpublished', [
      { no: 1, name: 'user_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'session_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 3,
        name: 'type',
        kind: 'enum',
        T: () => [
          'stream.video.sfu.models.TrackType',
          TrackType,
          'TRACK_TYPE_',
        ],
      },
      {
        no: 4,
        name: 'cause',
        kind: 'enum',
        T: () => [
          'stream.video.sfu.models.TrackUnpublishReason',
          TrackUnpublishReason,
          'TRACK_UNPUBLISH_REASON_',
        ],
      },
      { no: 5, name: 'participant', kind: 'message', T: () => Participant },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message stream.video.sfu.event.TrackUnpublished
 */
export const TrackUnpublished = new TrackUnpublished$Type();
// @generated message type with reflection information, may provide speed optimized methods
class JoinRequest$Type extends MessageType<JoinRequest> {
  constructor() {
    super('stream.video.sfu.event.JoinRequest', [
      { no: 1, name: 'token', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'session_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 3,
        name: 'subscriber_sdp',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 8,
        name: 'publisher_sdp',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 4,
        name: 'client_details',
        kind: 'message',
        T: () => ClientDetails,
      },
      { no: 5, name: 'migration', kind: 'message', T: () => Migration },
      {
        no: 6,
        name: 'fast_reconnect',
        kind: 'scalar',
        T: 8 /*ScalarType.BOOL*/,
      },
      {
        no: 7,
        name: 'reconnect_details',
        kind: 'message',
        T: () => ReconnectDetails,
      },
      {
        no: 9,
        name: 'preferred_publish_options',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => PublishOption,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message stream.video.sfu.event.JoinRequest
 */
export const JoinRequest = new JoinRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ReconnectDetails$Type extends MessageType<ReconnectDetails> {
  constructor() {
    super('stream.video.sfu.event.ReconnectDetails', [
      {
        no: 1,
        name: 'strategy',
        kind: 'enum',
        T: () => [
          'stream.video.sfu.models.WebsocketReconnectStrategy',
          WebsocketReconnectStrategy,
          'WEBSOCKET_RECONNECT_STRATEGY_',
        ],
      },
      {
        no: 3,
        name: 'announced_tracks',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => TrackInfo,
      },
      {
        no: 4,
        name: 'subscriptions',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => TrackSubscriptionDetails,
      },
      {
        no: 5,
        name: 'reconnect_attempt',
        kind: 'scalar',
        T: 13 /*ScalarType.UINT32*/,
      },
      {
        no: 6,
        name: 'from_sfu_id',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 7,
        name: 'previous_session_id',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message stream.video.sfu.event.ReconnectDetails
 */
export const ReconnectDetails = new ReconnectDetails$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Migration$Type extends MessageType<Migration> {
  constructor() {
    super('stream.video.sfu.event.Migration', [
      {
        no: 1,
        name: 'from_sfu_id',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 2,
        name: 'announced_tracks',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => TrackInfo,
      },
      {
        no: 3,
        name: 'subscriptions',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => TrackSubscriptionDetails,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message stream.video.sfu.event.Migration
 */
export const Migration = new Migration$Type();
// @generated message type with reflection information, may provide speed optimized methods
class JoinResponse$Type extends MessageType<JoinResponse> {
  constructor() {
    super('stream.video.sfu.event.JoinResponse', [
      { no: 1, name: 'call_state', kind: 'message', T: () => CallState },
      { no: 2, name: 'reconnected', kind: 'scalar', T: 8 /*ScalarType.BOOL*/ },
      {
        no: 3,
        name: 'fast_reconnect_deadline_seconds',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      {
        no: 4,
        name: 'publish_options',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => PublishOption,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message stream.video.sfu.event.JoinResponse
 */
export const JoinResponse = new JoinResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ParticipantJoined$Type extends MessageType<ParticipantJoined> {
  constructor() {
    super('stream.video.sfu.event.ParticipantJoined', [
      { no: 1, name: 'call_cid', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'participant', kind: 'message', T: () => Participant },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message stream.video.sfu.event.ParticipantJoined
 */
export const ParticipantJoined = new ParticipantJoined$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ParticipantLeft$Type extends MessageType<ParticipantLeft> {
  constructor() {
    super('stream.video.sfu.event.ParticipantLeft', [
      { no: 1, name: 'call_cid', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'participant', kind: 'message', T: () => Participant },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message stream.video.sfu.event.ParticipantLeft
 */
export const ParticipantLeft = new ParticipantLeft$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ParticipantUpdated$Type extends MessageType<ParticipantUpdated> {
  constructor() {
    super('stream.video.sfu.event.ParticipantUpdated', [
      { no: 1, name: 'call_cid', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'participant', kind: 'message', T: () => Participant },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message stream.video.sfu.event.ParticipantUpdated
 */
export const ParticipantUpdated = new ParticipantUpdated$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SubscriberOffer$Type extends MessageType<SubscriberOffer> {
  constructor() {
    super('stream.video.sfu.event.SubscriberOffer', [
      { no: 1, name: 'ice_restart', kind: 'scalar', T: 8 /*ScalarType.BOOL*/ },
      { no: 2, name: 'sdp', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message stream.video.sfu.event.SubscriberOffer
 */
export const SubscriberOffer = new SubscriberOffer$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PublisherAnswer$Type extends MessageType<PublisherAnswer> {
  constructor() {
    super('stream.video.sfu.event.PublisherAnswer', [
      { no: 1, name: 'sdp', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message stream.video.sfu.event.PublisherAnswer
 */
export const PublisherAnswer = new PublisherAnswer$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ConnectionQualityChanged$Type extends MessageType<ConnectionQualityChanged> {
  constructor() {
    super('stream.video.sfu.event.ConnectionQualityChanged', [
      {
        no: 1,
        name: 'connection_quality_updates',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => ConnectionQualityInfo,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message stream.video.sfu.event.ConnectionQualityChanged
 */
export const ConnectionQualityChanged = new ConnectionQualityChanged$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ConnectionQualityInfo$Type extends MessageType<ConnectionQualityInfo> {
  constructor() {
    super('stream.video.sfu.event.ConnectionQualityInfo', [
      { no: 1, name: 'user_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'session_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 3,
        name: 'connection_quality',
        kind: 'enum',
        T: () => [
          'stream.video.sfu.models.ConnectionQuality',
          ConnectionQuality,
          'CONNECTION_QUALITY_',
        ],
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message stream.video.sfu.event.ConnectionQualityInfo
 */
export const ConnectionQualityInfo = new ConnectionQualityInfo$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DominantSpeakerChanged$Type extends MessageType<DominantSpeakerChanged> {
  constructor() {
    super('stream.video.sfu.event.DominantSpeakerChanged', [
      { no: 1, name: 'user_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'session_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message stream.video.sfu.event.DominantSpeakerChanged
 */
export const DominantSpeakerChanged = new DominantSpeakerChanged$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AudioLevel$Type extends MessageType<AudioLevel> {
  constructor() {
    super('stream.video.sfu.event.AudioLevel', [
      { no: 1, name: 'user_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'session_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 3, name: 'level', kind: 'scalar', T: 2 /*ScalarType.FLOAT*/ },
      { no: 4, name: 'is_speaking', kind: 'scalar', T: 8 /*ScalarType.BOOL*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message stream.video.sfu.event.AudioLevel
 */
export const AudioLevel = new AudioLevel$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AudioLevelChanged$Type extends MessageType<AudioLevelChanged> {
  constructor() {
    super('stream.video.sfu.event.AudioLevelChanged', [
      {
        no: 1,
        name: 'audio_levels',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => AudioLevel,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message stream.video.sfu.event.AudioLevelChanged
 */
export const AudioLevelChanged = new AudioLevelChanged$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AudioSender$Type extends MessageType<AudioSender> {
  constructor() {
    super('stream.video.sfu.event.AudioSender', [
      { no: 2, name: 'codec', kind: 'message', T: () => Codec },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message stream.video.sfu.event.AudioSender
 */
export const AudioSender = new AudioSender$Type();
// @generated message type with reflection information, may provide speed optimized methods
class VideoLayerSetting$Type extends MessageType<VideoLayerSetting> {
  constructor() {
    super('stream.video.sfu.event.VideoLayerSetting', [
      { no: 1, name: 'name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'active', kind: 'scalar', T: 8 /*ScalarType.BOOL*/ },
      { no: 3, name: 'max_bitrate', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      {
        no: 4,
        name: 'scale_resolution_down_by',
        kind: 'scalar',
        T: 2 /*ScalarType.FLOAT*/,
      },
      { no: 6, name: 'codec', kind: 'message', T: () => Codec },
      {
        no: 7,
        name: 'max_framerate',
        kind: 'scalar',
        T: 13 /*ScalarType.UINT32*/,
      },
      {
        no: 8,
        name: 'scalability_mode',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message stream.video.sfu.event.VideoLayerSetting
 */
export const VideoLayerSetting = new VideoLayerSetting$Type();
// @generated message type with reflection information, may provide speed optimized methods
class VideoSender$Type extends MessageType<VideoSender> {
  constructor() {
    super('stream.video.sfu.event.VideoSender', [
      { no: 2, name: 'codec', kind: 'message', T: () => Codec },
      {
        no: 3,
        name: 'layers',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => VideoLayerSetting,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message stream.video.sfu.event.VideoSender
 */
export const VideoSender = new VideoSender$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ChangePublishQuality$Type extends MessageType<ChangePublishQuality> {
  constructor() {
    super('stream.video.sfu.event.ChangePublishQuality', [
      {
        no: 1,
        name: 'audio_senders',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => AudioSender,
      },
      {
        no: 2,
        name: 'video_senders',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => VideoSender,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message stream.video.sfu.event.ChangePublishQuality
 */
export const ChangePublishQuality = new ChangePublishQuality$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CallGrantsUpdated$Type extends MessageType<CallGrantsUpdated> {
  constructor() {
    super('stream.video.sfu.event.CallGrantsUpdated', [
      { no: 1, name: 'current_grants', kind: 'message', T: () => CallGrants },
      { no: 2, name: 'message', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message stream.video.sfu.event.CallGrantsUpdated
 */
export const CallGrantsUpdated = new CallGrantsUpdated$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GoAway$Type extends MessageType<GoAway> {
  constructor() {
    super('stream.video.sfu.event.GoAway', [
      {
        no: 1,
        name: 'reason',
        kind: 'enum',
        T: () => [
          'stream.video.sfu.models.GoAwayReason',
          GoAwayReason,
          'GO_AWAY_REASON_',
        ],
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message stream.video.sfu.event.GoAway
 */
export const GoAway = new GoAway$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CallEnded$Type extends MessageType<CallEnded> {
  constructor() {
    super('stream.video.sfu.event.CallEnded', [
      {
        no: 1,
        name: 'reason',
        kind: 'enum',
        T: () => [
          'stream.video.sfu.models.CallEndedReason',
          CallEndedReason,
          'CALL_ENDED_REASON_',
        ],
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message stream.video.sfu.event.CallEnded
 */
export const CallEnded = new CallEnded$Type();
